import React from "react"
import { Link, graphql } from "gatsby"
import { get, filter } from "lodash"
import { IoIosBed } from "@react-icons/all-files/io/IoIosBed"
import { FaBath } from "@react-icons/all-files/fa/FaBath"
import { FaRulerCombined } from "@react-icons/all-files/fa/FaRulerCombined"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Search = ({ location, data }) => {
  const searchText = get(location, "state.searchText", '').toLowerCase()
  const queryResult = [];

  queryResult.push(...data.allListing.nodes)
  const result = filter(
    queryResult,
    r =>
      (r.ListingId && r.ListingId.toLowerCase().includes(searchText)) ||
      (r.UnparsedAddress && r.UnparsedAddress.toLowerCase().includes(searchText)) ||
      (r.MLSAreaMajor && r.MLSAreaMajor.toLowerCase().includes(searchText)) ||
      (r.MLSAreaMinor && r.MLSAreaMinor.toLowerCase().includes(searchText)) ||
      (r.SubdivisionName && r.SubdivisionName.toLowerCase().includes(searchText))
  )

  const displayListings = result.map(item => {
    return (
      <Link
        className="post-link cha-search__result"
        to={item.path}
      >
        <div className="cha-search__result__container">
          <img
            className="cha-search__result__container_photo"
            alt="property"
            src={item.PrefferedPhoto?.replace('WIDTH', 800).replace('HEIGHT', 800 / 1.5) ?? ""}
          ></img>
        </div>
        <div className="cha-search__result__details">
          <div className="cha-search__result__details__price">
            ${new Intl.NumberFormat().format(item.ListPrice)}
          </div>
          <div className="cha-search__result__details__separator">|</div>
          {item.BedroomsTotal === null ? null : (
            <div className="cha-search__result__details__bath">
              <IoIosBed className="cha-search__result__details__bath__icon" />
              {item.BedroomsTotal}
            </div>
          )}
          {item.BathroomsTotalInteger === null ? null : (
            <div className="cha-search__result__details__bath__text">
              <FaBath className="cha-search__result__details__bath__text__icon" />
              {item.BathroomsTotalInteger}
            </div>
          )}
          {item.LivingArea === null ? null : (
            <div className="cha-search__result__details__living">
              <FaRulerCombined className="cha-search__result__details__living__icon" />
              {new Intl.NumberFormat().format(item.LivingArea)} SF
            </div>
          )}
        </div>
        <div className="cha-search__result__details__living__text">
          <FaMapMarkerAlt className="cha-search__result__details__living__text_icon" />
          {item.UnparsedAddress.toUpperCase()}
        </div>
      </Link>
    )
  })

  return (
    <Layout>
      <Seo title={"Search"} location={location} />
      <div className="cha-search__header" />
      <div className="cha-search__result-text">Site Search Results</div>
      <div className="cha-search__result-text">
        Property Search Results: {result.length}
      </div>
      <div className="cha-search__container">{displayListings}</div>
    </Layout>
  )
}

export default Search

export const query = graphql`
  query MyQuerySearch {
    allListing(filter: {StandardStatus: {ne: "Closed"}}, sort: { order: DESC, fields: ListPrice }) {
      nodes {
        ListPrice
        BedroomsTotal
        BathroomsTotalInteger
        UnparsedAddress
        PrefferedPhoto
        ListOfficeMlsId
        ListingId
        MLSAreaMajor
        MLSAreaMinor
        parsedAddress
        SubdivisionName
        LivingArea
        ArchitecturalStyle
        LotSizeAcres
        PropertyCondition
        StandardStatus
        path
      }
    }
  }
`
